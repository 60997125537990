




import Vue from 'vue';
import { SettlementAccountTypeLabels } from '../../model/SettlementAccountType';

export default Vue.extend({
  name: 'SettlementAccountTypeLabel',
  props: {
    accountType: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        SettlementAccountTypeLabels.find(
          i => i.value.toString() == this.accountType
        )?.label || ''
      );
    },
    variant: function (): string {
      return (
        SettlementAccountTypeLabels.find(
          i => i.value.toString() == this.accountType
        )?.variant || 'secondary'
      );
    },
  },
});
