







import Vue from 'vue';
import SettlementList from '../components/SettlementList.vue';
import SettlementSearchForm from '../components/SettlementSearchForm.vue';

export default Vue.extend({
  name: 'SettlementListContainer',
  components: {
    SettlementList,
    SettlementSearchForm,
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
