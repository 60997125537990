






































































import { Select, Option } from 'element-ui';
import Icon from '@/components/Icon/Icon.vue';
import { Term } from '@/common/Term';
import VueRouter from 'vue-router';
import { isHostAdmin } from '@/env';
import HostSearchInput from '@/components/Host/HostSearchInput.vue';
import { InvoiceFilterQuery, toFilter } from '../model/InvoiceFilter';
import { InvoiceStateLabels } from '../model/InvoiceState';
import { apolloClient } from '@/apolloClient';
import { SettlementService } from '../service/SettlementService';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { toStringArray } from '@/common/util/toStringArray';

const service = new SettlementService(apolloClient);

export default ContainerMixin.extend({
  name: 'InvoiceSearchForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Icon,
    HostSearchInput,
  },
  data() {
    const query = this.$route.query as InvoiceFilterQuery;

    return {
      isHostAdmin: isHostAdmin(),
      totalCount: 0,
      searchTerm: {
        startedAt: query.startedAt ? Number(query.startedAt) : null,
        endedAt: query.endedAt ? Number(query.endedAt) : null,
      } as Term,
      searchHostId: query.hostId || '',
      statusIn:
        toStringArray(query.statusIn) || InvoiceStateLabels.map(i => i.value),
      stateLabels: InvoiceStateLabels,
    };
  },
  computed: {
    allStatusChecked: {
      get(): boolean {
        return this.statusIn.length === InvoiceStateLabels.length;
      },
      set(newValue: boolean) {
        if (newValue) {
          this.statusIn = InvoiceStateLabels.map(i => i.value);
        } else if (this.statusIn.length === InvoiceStateLabels.length) {
          this.statusIn = [];
        }
      },
    },
  },
  methods: {
    clear() {
      this.searchHostId = '';
      this.searchTerm = { startedAt: null, endedAt: null };
      this.statusIn = InvoiceStateLabels.map(i => i.value);

      this.$router.push({ query: undefined }).catch();
    },
    onClickSearch() {
      const filter = this.buildFilter();
      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
    buildFilter(): InvoiceFilterQuery {
      const filter: InvoiceFilterQuery = {};

      if (this.searchTerm.startedAt) {
        filter.startedAt = this.searchTerm.startedAt.toString();
      }

      if (this.searchTerm.endedAt) {
        filter.endedAt = this.searchTerm.endedAt.toString();
      }

      if (this.searchHostId) {
        filter.hostId = this.searchHostId;
      }

      filter.statusIn = this.statusIn;

      return filter;
    },
    async downloadFile(): Promise<void> {
      const filter = this.buildFilter();
      try {
        const result = await service.generateInvoiceDepositFile(
          toFilter(filter)
        );

        if (!result.success) {
          return this.$modal.show({
            title: '엑셀 다운로드 실패',
            message: result.message,
            type: 'warning',
          });
        }

        window.location.href = result.url;
      } catch (err) {
        this.showErrorAlert(err, '엑셀 다운로드 실패');
      }
    },
    async downloadFileForHost(): Promise<void> {
      const filter = {
        ...this.buildFilter(),
        statusIn: ['COMPLETED'],
        hostId: this.$store.state.userToken.hostId,
      };

      try {
        const result = await service.generateInvoiceFileForHost(
          toFilter(filter)
        );

        if (!result.success) {
          return this.$modal.show({
            title: '엑셀 다운로드 실패',
            message: result.message,
            type: 'warning',
          });
        }

        window.location.href = result.url;
      } catch (err) {
        this.showErrorAlert(err, '엑셀 다운로드 실패');
      }
    },
  },
});
