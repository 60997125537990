







import Vue from 'vue';
import InvoiceView from '../components/InvoiceView.vue';
import SettlementList from '../components/SettlementList.vue';

export default Vue.extend({
  name: 'InvoiceViewContainer',
  components: {
    InvoiceView,
    SettlementList,
  },
  data() {
    return {
      invoiceId: this.$route.params.id,
    };
  },
});
