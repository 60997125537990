







import Vue from 'vue';
import SettlementView from '../components/SettlementView.vue';
import AccountList from '../components/AccountList.vue';

export default Vue.extend({
  name: 'SettlementViewContainer',
  components: {
    SettlementView,
    AccountList,
  },
  data() {
    return {
      settlementId: this.$route.params.id,
    };
  },
});
