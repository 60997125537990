export enum AccountReferredType {
  ABSENCE = 'ABSENCE',
  CANCEL_FEE = 'CANCEL_FEE',
  DIGITAL = 'DIGITAL',
  ETC = 'ETC',
  FRIP = 'FRIP',
  GOODS = 'GOODS',
}

export const AccountReferredTypeLabels: {
  value: AccountReferredType;
  label: string;
}[] = [
  {
    value: AccountReferredType.FRIP,
    label: '프립',
  },
  {
    value: AccountReferredType.GOODS,
    label: '배송상품',
  },
  {
    value: AccountReferredType.DIGITAL,
    label: '컨텐츠',
  },
  {
    value: AccountReferredType.ABSENCE,
    label: '불참수수료',
  },
  {
    value: AccountReferredType.CANCEL_FEE,
    label: '취소수수료',
  },
  {
    value: AccountReferredType.ETC,
    label: '기타',
  },
];
