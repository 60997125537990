export enum SettlementAccountType {
  //지불
  PAYABLE = 'PAYABLE',
  //회수
  RECEIVABLE = 'RECEIVABLE',
}

export const SettlementAccountTypeLabels: {
  value: SettlementAccountType;
  label: string;
  variant: string;
}[] = [
  {
    value: SettlementAccountType.PAYABLE,
    label: '지급',
    variant: 'primary',
  },
  {
    value: SettlementAccountType.RECEIVABLE,
    label: '회수',
    variant: 'danger',
  },
];
