




































































































import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { SETTLEMENT_ACCOUNT_LIST } from '../graphql/query';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';
import { Term } from '@/common/Term';
import {
  SettlementAccountFilter,
  SettlementAccountFilterQuery,
} from '../model/SettlementAccountFilter';
import { SettlementAccount } from '../model/SettlementAccount';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { AccountReferredType } from '../model/AccountReferredType';
import { SettlementAccountType } from '../model/SettlementAccountType';
import SettlementAccountTypeLabel from './controls/SettlementAccountTypeLabel.vue';
import AccountReferredTypeLabel from './controls/AccountReferredTypeLabel.vue';
import { isHostAdmin } from '@/env';

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

export default ContainerMixin.extend({
  name: 'AccountList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
    Spinner,
    MoneyLabel,
    SettlementAccountTypeLabel,
    AccountReferredTypeLabel,
  },
  props: {
    settlementId: {
      type: String,
    },
  },
  data() {
    return {
      loading: 0,
      accounts: [] as SettlementAccount[],
      totalCount: 0,
      size: 50,
      isHostAdmin: isHostAdmin(),
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): SettlementAccountFilter {
      if (this.settlementId) {
        return {
          settlementIds: [this.settlementId],
        };
      }

      const query = this.$route.query as SettlementAccountFilterQuery;
      var term: Term | null = null;
      if (query.startedAt || query.endedAt) {
        term = {
          startedAt: query.startedAt ? Number(query.startedAt) : null,
          endedAt: query.endedAt ? Number(query.endedAt) : null,
        };
      }

      return {
        hostId: query.hostId || null,
        orderIds: this.toArray(query.orderIds) || null,
        productIds: this.toArray(query.productIds) || null,
        referredTypes:
          this.toArray(query.productreferredTypesIds)?.map(
            i => i as AccountReferredType
          ) || null,
        settlementIds: this.toArray(query.settlementIds) || null,
        store: query.store || null,
        titleLike: query.titleLike || null,
        type: (query.type as SettlementAccountType) || null,
        term: term,
      };
    },
  },
  methods: {
    toArray(items?: string | string[]): string[] | undefined {
      if (items) {
        if (Array.isArray(items)) {
          return items as string[];
        }
        return [items];
      }
    },
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    toDateString(date: number | null): string {
      if (date) {
        return this.$moment(date).format('YYYY-MM-DD');
      }
      return '';
    },
    refresh() {
      this.$apollo.queries.accounts.refresh();
    },
  },
  apollo: {
    accounts: {
      query: SETTLEMENT_ACCOUNT_LIST,
      variables(): {
        page: number;
        size: number;
        filter: SettlementAccountFilter;
      } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      // manual: true,
      result(
        result: ApolloQueryResult<{
          settlement: { accounts: Connection<SettlementAccount> };
        }>
      ) {
        this.totalCount = result.data.settlement.accounts.totalCount;
      },
      update: (data: {
        settlement: { accounts: Connection<SettlementAccount> };
      }) => data.settlement.accounts.edges.map(edge => edge.node),
    },
  },
});
