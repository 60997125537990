






































































import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { TAX_INVOICE_LIST } from '../graphql/query';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';
import {
  TaxInvoiceFilter,
  TaxInvoiceFilterQuery,
  toFilter,
} from '../model/TaxInvoiceFilter';
import { TaxInvoice } from '../model/TaxInvoice';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import TaxPlayerTypeLabel from './controls/TaxPlayerTypeLabel.vue';

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

export default ContainerMixin.extend({
  name: 'TaxInvoiceList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
    Spinner,
    MoneyLabel,
    TaxPlayerTypeLabel,
  },
  data() {
    return {
      loading: 0,
      taxInvoices: [] as TaxInvoice[],
      totalCount: 0,
      size: 20,
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): TaxInvoiceFilter {
      const query = this.$route.query as TaxInvoiceFilterQuery;
      return toFilter(query);
    },
  },
  methods: {
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    toDateString(date: number | null): string {
      if (date) {
        return this.$moment(date).format('YYYY-MM-DD');
      }
      return '';
    },
    refresh() {
      this.$apollo.queries.taxInvoices.refresh();
    },
    toMoney(
      value: string,
      currencyCode: string
    ): { value: string; currencyCode: string } {
      return {
        value,
        currencyCode,
      };
    },
  },
  apollo: {
    taxInvoices: {
      query: TAX_INVOICE_LIST,
      variables(): {
        page: number;
        size: number;
        filter: TaxInvoiceFilter;
      } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      // manual: true,
      result(
        result: ApolloQueryResult<{
          settlement: { taxInvoices: Connection<TaxInvoice> };
        }>
      ) {
        this.totalCount = result.data.settlement.taxInvoices.totalCount;
      },
      update: (data: { settlement: { taxInvoices: Connection<TaxInvoice> } }) =>
        data.settlement.taxInvoices.edges.map(edge => edge.node),
    },
  },
});
