import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  COMPLETE_INVOICE_MUTATION,
  GENERATE_INVOICE_FILE,
  GENERATE_TAX_INVOICE_FILE,
  PROCESS_INVOICE_MUTATION,
  REQUEST_INVOICE_MUTATION,
  GENERATE_INVOICE_FILE_FOR_HOST,
  REQUEST_MANUAL_RECEIVE_OF_MINUS_SETTLEMENTS_MUTATION,
} from '../graphql/mutation';
import { InvoiceFilter } from '../model/InvoiceFilter';
import { InvoiceParam } from '../model/InvoiceParam';
import { InvoiceState } from '../model/InvoiceState';

export class SettlementService {
  constructor(private readonly apollo: ApolloClient<NormalizedCacheObject>) {}

  public async requestInvoice(
    param: InvoiceParam
  ): Promise<{ id: string; status: InvoiceState }> {
    const { data } = await this.apollo.mutate({
      mutation: REQUEST_INVOICE_MUTATION,
      variables: {
        param: param,
      },
    });

    return data.requestInvoice;
  }

  public async requestManualReceiveOfMinusSettlements(
    param: InvoiceParam
  ): Promise<{ id: string; status: InvoiceState }> {
    const { data } = await this.apollo.mutate({
      mutation: REQUEST_MANUAL_RECEIVE_OF_MINUS_SETTLEMENTS_MUTATION,
      variables: {
        param: param,
      },
    });

    return data.requestManualReceiveOfMinusSettlements;
  }

  public async processInvoices(invoiceIds: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: PROCESS_INVOICE_MUTATION,
      variables: {
        invoiceIds,
      },
    });

    console.log('processInvoices:', data.processInvoices);
  }

  public async completeInvoices(invoiceIds: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: COMPLETE_INVOICE_MUTATION,
      variables: {
        invoiceIds,
      },
    });

    console.log('completeInvoices:', data.completeInvoices);
  }

  public async generateInvoiceDepositFile(
    filter: InvoiceFilter
  ): Promise<SettlementFileResult> {
    const { data } = await this.apollo.mutate({
      mutation: GENERATE_INVOICE_FILE,
      variables: {
        filter: filter,
      },
    });
    console.log('generateInvoiceDepositFile:', data);
    return data.generateInvoicesFile;
  }

  public async generateInvoiceFileForHost(
    filter: InvoiceFilter
  ): Promise<SettlementFileResult> {
    const { data } = await this.apollo.mutate({
      mutation: GENERATE_INVOICE_FILE_FOR_HOST,
      variables: {
        filter: filter,
      },
    });
    console.log('generateInvoiceFileForHost:', data);
    return data.generateInvoicesFileForHost;
  }

  public async generateTaxInvoiceDepositFile(
    yearMonth: string
  ): Promise<SettlementFileResult> {
    const { data } = await this.apollo.mutate({
      mutation: GENERATE_TAX_INVOICE_FILE,
      variables: {
        yearMonth: yearMonth,
      },
    });
    console.log('generateTaxInvoiceDepositFile:', data);
    return data.generateTaxInvoiceFile;
  }
}

interface SettlementFileResult {
  success: boolean;
  message: string;
  url: string;
}
