import { Term } from '@/common/Term';
import { toStringArray } from '@/common/util/toStringArray';
import { Dictionary } from 'vue-router/types/router';
import { InvoiceState } from './InvoiceState';

export interface InvoiceFilter {
  hostId: string | null;
  term: Term | null;
  statusIn: InvoiceState[] | null;
}

export interface InvoiceFilterQuery
  extends Dictionary<string | string[] | undefined> {
  hostId?: string;
  startedAt?: string;
  endedAt?: string;
  statusIn?: string[];
}

export const toFilter = (query: InvoiceFilterQuery): InvoiceFilter => {
  let term: Term | null = null;
  if (query.startedAt || query.endedAt) {
    term = {
      startedAt: query.startedAt ? Number(query.startedAt) : null,
      endedAt: query.endedAt ? Number(query.endedAt) : null,
    };
  }
  return {
    hostId: query.hostId || null,
    term: term,
    statusIn:
      toStringArray(query.statusIn)?.map(i => i as InvoiceState) || null,
  };
};
