import { Term } from '@/common/Term';
import { Dictionary } from 'vue-router/types/router';

export interface TaxInvoiceFilter {
  hostId: string | null;
  term: Term | null;
}

export interface TaxInvoiceFilterQuery
  extends Dictionary<string | string[] | undefined> {
  hostId?: string;
  startedAt?: string;
  endedAt?: string;
}

export const toFilter = (query: TaxInvoiceFilterQuery): TaxInvoiceFilter => {
  let term: Term | null = null;
  if (query.startedAt || query.endedAt) {
    term = {
      startedAt: query.startedAt ? Number(query.startedAt) : null,
      endedAt: query.endedAt ? Number(query.endedAt) : null,
    };
  }
  return {
    hostId: query.hostId || null,
    term: term,
  };
};
