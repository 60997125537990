













































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import Icon from '@/components/Icon/Icon.vue';
import { Term } from '@/common/Term';
import VueRouter from 'vue-router';
import { SettlementFilterQuery } from '../model/SettlementFilter';
import { isHostAdmin } from '@/env';
import HostSearchInput from '@/components/Host/HostSearchInput.vue';

const minusSettlementOptions = [
  { value: null, text: '전체' },
  { value: true, text: '마이너스 정산서만 보기' },
  { value: false, text: '마이너스 정산서 제외' },
];

export default Vue.extend({
  name: 'SettlementSearchForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Icon,
    HostSearchInput,
  },
  data() {
    const query = this.$route.query as SettlementFilterQuery;

    return {
      isHostAdmin: isHostAdmin(),
      minusSettlementOptions,
      totalCount: 0,
      searchTerm: {
        startedAt: query.startedAt ? Number(query.startedAt) : null,
        endedAt: query.endedAt ? Number(query.endedAt) : null,
      } as Term,
      searchHostId: query.hostId || '',
      hasMinusTotalPayment: query.hasMinusTotalPayment,
    };
  },
  methods: {
    clear() {
      this.searchHostId = '';
      this.searchTerm = { startedAt: null, endedAt: null };

      this.$router.push({ query: undefined }).catch();
    },
    buildFilter() {
      const filter: SettlementFilterQuery = {};

      if (this.searchTerm.startedAt) {
        filter.startedAt = this.searchTerm.startedAt.toString();
      }

      if (this.searchTerm.endedAt) {
        filter.endedAt = this.searchTerm.endedAt.toString();
      }

      if (this.searchHostId) {
        filter.hostId = this.searchHostId;
      }

      if (this.hasMinusTotalPayment !== null) {
        filter.hasMinusTotalPayment = this.hasMinusTotalPayment;
      }

      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
  },
});
