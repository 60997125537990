import { gql } from 'apollo-boost';

export const REQUEST_INVOICE_MUTATION = gql`
  mutation RequestInvoice($param: InvoiceParamInput!) {
    requestInvoice(param: $param) {
      id
      status
    }
  }
`;

export const REQUEST_MANUAL_RECEIVE_OF_MINUS_SETTLEMENTS_MUTATION = gql`
  mutation RequestManualReceiveOfMinusSettlements($param: InvoiceParamInput!) {
    requestManualReceiveOfMinusSettlements(param: $param) {
      id
      status
    }
  }
`;

export const PROCESS_INVOICE_MUTATION = gql`
  mutation ProcessInvoices($invoiceIds: [String!]!) {
    processInvoices(invoiceIds: $invoiceIds) {
      id
      startedAt
      status
    }
  }
`;

export const COMPLETE_INVOICE_MUTATION = gql`
  mutation CompleteInvoices($invoiceIds: [String!]!) {
    completeInvoices(invoiceIds: $invoiceIds) {
      id
      startedAt
      completedAt
      status
    }
  }
`;

export const GENERATE_INVOICE_FILE = gql`
  mutation GenerateInvoicesFile($filter: InvoiceFilterInput!) {
    generateInvoicesFile(filter: $filter) {
      success
      message
      url
    }
  }
`;

export const GENERATE_INVOICE_FILE_FOR_HOST = gql`
  mutation GenerateInvoicesFileForHost($filter: InvoiceFilterInput!) {
    generateInvoicesFileForHost(filter: $filter) {
      success
      message
      url
    }
  }
`;

export const GENERATE_TAX_INVOICE_FILE = gql`
  mutation GenerateTaxInvoicesFile($yearMonth: String!) {
    generateTaxInvoiceFile(yearMonth: $yearMonth) {
      success
      message
      url
    }
  }
`;
