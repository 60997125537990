




import Vue from 'vue';
import { InvoiceStateLabels } from '../../model/InvoiceState';

export default Vue.extend({
  name: 'InvoiceStatusLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        InvoiceStateLabels.find(i => i.value.toString() == this.status)
          ?.label || ''
      );
    },
    variant: function (): string {
      return (
        InvoiceStateLabels.find(i => i.value.toString() == this.status)
          ?.variant || 'secondary'
      );
    },
  },
});
