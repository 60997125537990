







import Vue from 'vue';
import TaxInvoiceSearchForm from '../components/TaxInvoiceSearchForm.vue';
import TaxInvoiceList from '../components/TaxInvoiceList.vue';

export default Vue.extend({
  name: 'TaxInvoiceListContainer',
  components: {
    TaxInvoiceList,
    TaxInvoiceSearchForm,
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
