












































































import Vue from 'vue';
import { isHostAdmin } from '@/env';
import { INVOICE_QUERY } from '../graphql/query';
import { Invoice } from '../model/Invoice';
import InvoiceStatusLabel from './controls/InvoiceStatusLabel.vue';
import Instant from '@/components/Labels/Instant.vue';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';

export default Vue.extend({
  name: 'InvoiceView',
  components: {
    InvoiceStatusLabel,
    Instant,
    MoneyLabel,
  },
  props: {
    invoiceId: {
      type: String,
    },
  },
  data() {
    return {
      isHostAdmin: isHostAdmin(),
      invoice: {} as Invoice,
    };
  },
  computed: {
    totalPayment: function (): string {
      return this.invoice.totalPayment?.value
        ? Number(this.invoice.totalPayment?.value).toLocaleString()
        : '';
    },
    paymentForPayable: function (): string {
      return this.invoice.paymentForPayable?.value
        ? Number(this.invoice.paymentForPayable?.value).toLocaleString()
        : '';
    },
    paymentForReceivable: function (): string {
      return this.invoice.paymentForReceivable?.value
        ? Number(this.invoice.paymentForReceivable?.value).toLocaleString()
        : '';
    },
    hostName: function (): string {
      return this.invoice.host?.name || '';
    },
  },
  apollo: {
    invoice: {
      query: INVOICE_QUERY,
      variables(): { id: string } {
        return {
          id: this.invoiceId,
        };
      },
      update: data => {
        return data.settlement.invoice;
      },
    },
  },
});
