







import Vue from 'vue';
import InvoiceSearchForm from '../components/InvoiceSearchForm.vue';
import InvoiceList from '../components/InvoiceList.vue';

export default Vue.extend({
  name: 'InvoiceListContainer',
  components: {
    InvoiceList,
    InvoiceSearchForm,
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
