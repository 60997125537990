export enum InvoiceState {
  // 지급 대기
  READY = 'READY',

  // 지급 처리중
  PROGRESSING = 'PROGRESSING',

  // 지급 완료"
  COMPLETED = 'COMPLETED',

  // 회수 요청
  RECEIVE_REQUESTED = 'RECEIVE_REQUESTED',

  // 회수 완료
  RECEIVED = 'RECEIVED',
}

export const InvoiceStateLabels: {
  value: InvoiceState;
  label: string;
  variant: string;
}[] = [
  {
    value: InvoiceState.READY,
    label: '지급 대기',
    variant: 'primary',
  },
  {
    value: InvoiceState.RECEIVE_REQUESTED,
    label: '회수 요청',
    variant: 'primary',
  },
  {
    value: InvoiceState.PROGRESSING,
    label: '지급 처리중',
    variant: 'info',
  },
  {
    value: InvoiceState.COMPLETED,
    label: '지급 완료',
    variant: 'success',
  },
  {
    value: InvoiceState.RECEIVED,
    label: '회수 완료',
    variant: 'success',
  },
];
