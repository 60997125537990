




import Vue from 'vue';
import { AccountReferredTypeLabels } from '../../model/AccountReferredType';

export default Vue.extend({
  name: 'AccountReferredTypeLabel',
  props: {
    accountReferredType: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        AccountReferredTypeLabels.find(
          i => i.value.toString() == this.accountReferredType
        )?.label || ''
      );
    },
  },
});
