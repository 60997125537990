



































import Vue from 'vue';
import { isHostAdmin } from '@/env';
import { Settlement } from '../model/Settlement';
import { InvoiceStateLabels } from '../model/InvoiceState';
import { SETTLEMENT_QUERY } from '../graphql/query';

export default Vue.extend({
  name: 'SettlementView',
  props: {
    settlementId: {
      type: String,
    },
  },
  data() {
    return {
      isHostAdmin: isHostAdmin(),
      settlement: {} as Settlement,
    };
  },
  computed: {
    totalPayment: function (): string {
      return this.settlement.totalPayment?.value
        ? Number(this.settlement.totalPayment?.value).toLocaleString()
        : '';
    },
    totalFee: function (): string {
      return this.settlement.totalFee?.value
        ? Number(this.settlement.totalFee?.value).toLocaleString()
        : '';
    },
    hostName: function (): string {
      return this.settlement.host?.name || '';
    },
    invoiceInfo: function (): string {
      if (!this.settlement.invoice) {
        return '미지급';
      }
      const statusLabel = InvoiceStateLabels.find(
        i => i.value == this.settlement.invoice?.status
      );
      return `${this.settlement.invoice.title} [${statusLabel?.label || ''}]`;
    },
  },
  apollo: {
    settlement: {
      query: SETTLEMENT_QUERY,
      variables(): { id: string } {
        return {
          id: this.settlementId,
        };
      },
      update: data => {
        return data.settlement.settlement;
      },
    },
  },
});
