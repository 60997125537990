







































































































import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  COUNT_UNCOLLECTED_MINUS_SETTLEMENT,
  SETTLEMENT_LIST,
} from '../graphql/query';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';
import { Term } from '@/common/Term';
import {
  SettlementFilter,
  SettlementFilterQuery,
} from '../model/SettlementFilter';
import { Settlement } from '../model/Settlement';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { isHostAdmin } from '@/env';
import { InvoiceStateLabels } from '../model/InvoiceState';
import { apolloClient } from '@/apolloClient';
import { SettlementService } from '../service/SettlementService';

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

const service = new SettlementService(apolloClient);
let issueFailedReason = '';

export default ContainerMixin.extend({
  name: 'SettlementList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
    Spinner,
    MoneyLabel,
  },
  props: {
    invoiceId: {
      type: String,
    },
  },
  data() {
    return {
      loading: 0,
      settlements: [] as Settlement[],
      selection: [] as Settlement[],
      totalCount: 0,
      minusTotalCount: 0,
      size: 50,
      isHostAdmin: isHostAdmin(),
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    isShowAction(): boolean {
      return this.isHostAdmin && !this.invoiceId;
    },
    isShowActionForMinusSettlement(): boolean {
      return !this.isHostAdmin && !this.invoiceId;
    },
    filter(): SettlementFilter {
      if (this.invoiceId) {
        return {
          invoiceIds: [this.invoiceId],
        };
      }

      const query = this.$route.query as SettlementFilterQuery;
      var term: Term | null = null;
      if (query.startedAt || query.endedAt) {
        term = {
          startedAt: query.startedAt ? Number(query.startedAt) : null,
          endedAt: query.endedAt ? Number(query.endedAt) : null,
        };
      }

      return {
        hostId: query.hostId || null,
        invoiceIds: this.toArray(query.invoiceIds) || null,
        taxIds: this.toArray(query.taxIds) || null,
        uncollected: query.uncollected?.toString() == 'true' || null,
        unissued: query.unissued?.toString() == 'true' || null,
        term: term,
        hasMinusTotalPayment:
          query.hasMinusTotalPayment == 'true'
            ? true
            : query.hasMinusTotalPayment == 'false'
            ? false
            : null,
      };
    },
  },
  methods: {
    toArray(items?: string | string[]): string[] | undefined {
      if (items) {
        if (Array.isArray(items)) {
          return items as string[];
        }
        return [items];
      }
    },
    handleSelectionChange(items: Settlement[]): void {
      this.selection = items;
    },
    canIssue(): boolean {
      issueFailedReason = '';
      let minusSettlementIds: string[] = [];
      let selectedMinusSettlementIds: string[] = [];
      this.settlements.forEach(function (settlement) {
        if (Number(settlement.totalPayment.value) < 0 && !settlement.invoice) {
          minusSettlementIds.push(settlement.id);
        }
      });
      if (this.selection.length > 0) {
        this.selection.forEach(function (selectedMinusSettlement) {
          minusSettlementIds.filter(function (minusSettlementsId) {
            if (minusSettlementsId === selectedMinusSettlement.id) {
              selectedMinusSettlementIds.push(selectedMinusSettlement.id);
            }
          });
        });
      }

      if (minusSettlementIds.length !== selectedMinusSettlementIds.length) {
        issueFailedReason =
          '<span>정산금이 0원 또는 마이너스인 정산서를 포함하여, <br>지급 요청하여야 합니다.</span>';
        return false;
      }

      if (
        this.minusTotalCount != 0 &&
        selectedMinusSettlementIds.length < this.minusTotalCount
      ) {
        issueFailedReason =
          '<span>회수되지 않은 마이너스 정산서가 남아 있습니다. <br>카카오톡 채널 [프립호스트]로 문의 남겨주세요.</span>';
        return false;
      }

      if (this.selection.length == 0) {
        issueFailedReason = '선택한 정산서가 없습니다.';
        return false;
      }

      if (
        this.selection.reduce(
          (amount, item) => amount + Number(item.totalPayment.value),
          0
        ) < 0
      ) {
        issueFailedReason = '정산 금액이 0보다 작습니다.';
        return false;
      }

      if (!this.selection.every(i => !i.invoice)) {
        issueFailedReason = '지급 완료된 정산서가 포함되었습니다.';
        return false;
      }

      return true;
    },
    canRequestForReceive(): boolean {
      if (this.selection.length == 0) {
        return false;
      }
      if (
        this.selection.reduce(
          (amount, item) => amount + Number(item.totalPayment.value),
          0
        ) >= 0
      ) {
        return false;
      }
      return this.selection.every(i => !i.invoice);
    },
    async onClickIssue() {
      if (this.selection.length == 0) {
        return this.$modal.show({
          title: '지급 신청 실패',
          html: '선택된 항목이 없습니다.',
          type: 'warning',
        });
      }

      const amount = this.selection.reduce(
        (amount, item) => amount + Number(item.totalPayment.value),
        0
      );

      if (!this.canIssue() && issueFailedReason !== '') {
        return this.$modal.show({
          title: '지급 신청 실패',
          html: issueFailedReason,
          type: 'warning',
        });
      }

      return this.$modal.show(
        {
          title: '지급 요청',
          html: `정산금 총 ${amount.toLocaleString()}원을 지급요청 하시겠습니까??`,
          type: 'warning',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await service.requestInvoice({
              hostId: this.selection[0].host.id,
              settlementIds: this.selection.map(i => i.id),
            });
            this.$apollo.queries.settlements.refresh();
          } catch (err) {
            this.showErrorAlert(err, '지급 신청 실패');
          }
        }
      );
    },
    async onClickRequestForReceive() {
      if (this.selection.length == 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }
      if (this.selection.some(item => Number(item.totalPayment.value) > 0)) {
        return this.$alert(
          '마이너스 정산서가 아닌 정산서가 포함되어 있습니다.',
          '실패'
        );
      }

      return this.$modal.show(
        {
          title: '회수 요청',
          html: `정산금 회수 요청을 하시겠습니까??`,
          type: 'warning',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await service.requestManualReceiveOfMinusSettlements({
              settlementIds: this.selection.map(i => i.id),
            });
            this.$apollo.queries.settlements.refresh();
          } catch (err) {
            this.showErrorAlert(err, '회수 요청 실패');
          }
        }
      );
    },

    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    toDateString(date: number | null): string {
      if (date) {
        return this.$moment(date).format('YYYY-MM-DD');
      }
      return '';
    },
    toInvoiceInfo(row: Settlement): string {
      if (!row.invoice) {
        return '미지급';
      }
      return (
        InvoiceStateLabels.find(i => i.value == row.invoice?.status)?.label ||
        ''
      );
    },
    refresh() {
      this.$apollo.queries.settlements.refresh();
    },
  },
  apollo: {
    settlements: {
      query: SETTLEMENT_LIST,
      variables(): {
        page: number;
        size: number;
        filter: SettlementFilter;
      } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      // manual: true,
      result(
        result: ApolloQueryResult<{
          settlement: { settlements: Connection<Settlement> };
        }>
      ) {
        this.totalCount = result.data.settlement.settlements.totalCount;
      },
      update: (data: { settlement: { settlements: Connection<Settlement> } }) =>
        data.settlement.settlements.edges.map(edge => edge.node),
    },
    uncollectedMinusCount: {
      // 미회수 마이너스 정산서 카운트
      query: COUNT_UNCOLLECTED_MINUS_SETTLEMENT,
      variables(): {
        page: number;
        size: number;
        filter: SettlementFilter;
      } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: { uncollected: true, hasMinusTotalPayment: true },
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      manual: true,
      result(
        result: ApolloQueryResult<{
          settlement: { settlements: Connection<Settlement> };
        }>
      ) {
        this.minusTotalCount = result.data.settlement.settlements.totalCount;
      },
    },
  },
});
