import { gql } from 'apollo-boost';

export const SETTLEMENT_ACCOUNT_LIST = gql`
  query GetAccountListByFilter(
    $filter: SettlementAccountFilterInput
    $page: Int
    $size: Int
  ) {
    settlement {
      accounts(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            title
            payment {
              value
              currencyCode
            }
            host {
              id
              name
            }
            commissionFee {
              value
              currencyCode
            }
            referredType
            settled
            settlement {
              id
            }
            type
            order {
              id
            }
            orderItem {
              id
            }
            canceled
            createdAt
          }
        }
      }
    }
  }
`;

export const SETTLEMENT_LIST = gql`
  query GetSettlementListByFilter(
    $filter: SettlementFilterInput
    $page: Int
    $size: Int
  ) {
    settlement {
      settlements(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            periodCode
            title
            totalPayment {
              value
              currencyCode
            }
            totalFee {
              value
              currencyCode
            }
            host {
              id
              name
            }
            startedDate
            endedDate
            invoice {
              id
              title
              status
              createdAt
              completedAt
            }
            createdAt
          }
        }
      }
    }
  }
`;

export const SETTLEMENT_QUERY = gql`
  query GetSettlement($id: ID!) {
    settlement {
      settlement(id: $id) {
        id
        periodCode
        title
        totalPayment {
          value
          currencyCode
        }
        totalFee {
          value
          currencyCode
        }
        host {
          id
          name
        }
        startedDate
        endedDate
        invoice {
          id
          title
          status
          createdAt
          completedAt
        }
        createdAt
      }
    }
  }
`;

export const INVOICE_LIST = gql`
  query GetInvoiceList($filter: InvoiceFilterInput, $page: Int, $size: Int) {
    settlement {
      invoices(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            title
            host {
              id
              name
            }
            totalPayment {
              value
              currencyCode
            }
            paymentForPayable {
              value
              currencyCode
            }
            paymentForReceivable {
              value
              currencyCode
            }
            bankAccount {
              bank {
                code
                name
              }
              accountNo
              holder
            }
            createdAt
            startedAt
            completedAt
            status
          }
        }
      }
    }
  }
`;

export const INVOICE_QUERY = gql`
  query GetInvoice($id: ID!) {
    settlement {
      invoice(id: $id) {
        id
        title
        host {
          id
          name
        }
        totalPayment {
          value
          currencyCode
        }
        paymentForPayable {
          value
          currencyCode
        }
        paymentForReceivable {
          value
          currencyCode
        }
        bankAccount {
          bank {
            code
            name
          }
          accountNo
          holder
        }
        createdAt
        startedAt
        completedAt
        status
      }
    }
  }
`;

export const TAX_INVOICE_LIST = gql`
  query GetTaxInvoiceList(
    $filter: TaxInvoiceFilterInput
    $page: Int
    $size: Int
  ) {
    settlement {
      taxInvoices(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            date
            title
            host {
              id
              name
            }
            businessInfo {
              businessType
              taxPayerType
              taxPayerName
              companyCode
            }
            surTax {
              supplyPrice
              tax
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export const COUNT_UNCOLLECTED_MINUS_SETTLEMENT = gql`
  query GetSettlementListByFilter(
    $filter: SettlementFilterInput
    $page: Int
    $size: Int
  ) {
    settlement {
      settlements(filter: $filter, page: $page, size: $size) {
        totalCount
      }
    }
  }
`;
