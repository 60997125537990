




import Vue from 'vue';

export default Vue.extend({
  name: 'TaxPlayerTypeLabel',
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      if (this.type == 'CORPORATION') {
        return '법인';
      }
      return '개인';
    },
    variant: function (): string {
      if (this.type == 'CORPORATION') {
        return 'primary';
      }
      return 'success';
    },
  },
});
