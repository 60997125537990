






































import { Select, Option } from 'element-ui';
import Icon from '@/components/Icon/Icon.vue';
import { Term } from '@/common/Term';
import VueRouter from 'vue-router';
import { isHostAdmin } from '@/env';
import HostSearchInput from '@/components/Host/HostSearchInput.vue';
import { TaxInvoiceFilterQuery } from '../model/TaxInvoiceFilter';
import { apolloClient } from '@/apolloClient';
import { SettlementService } from '../service/SettlementService';
import { ContainerMixin } from '@/common/mixin/containerMixin';

const service = new SettlementService(apolloClient);

export default ContainerMixin.extend({
  name: 'TaxInvoiceSearchForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Icon,
    HostSearchInput,
  },
  data() {
    const query = this.$route.query as TaxInvoiceFilterQuery;

    return {
      isHostAdmin: isHostAdmin(),
      totalCount: 0,
      searchTerm: {
        startedAt: query.startedAt ? Number(query.startedAt) : null,
        endedAt: query.endedAt ? Number(query.endedAt) : null,
      } as Term,
      searchHostId: query.hostId || '',
    };
  },
  methods: {
    clear() {
      this.searchHostId = '';
      this.searchTerm = { startedAt: null, endedAt: null };

      this.$router.push({ query: undefined }).catch();
    },
    onClickSearch() {
      const filter = this.buildFilter();
      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
    buildFilter(): TaxInvoiceFilterQuery {
      const filter: TaxInvoiceFilterQuery = {};

      if (this.searchTerm.startedAt) {
        filter.startedAt = this.searchTerm.startedAt.toString();
      }

      if (this.searchTerm.endedAt) {
        filter.endedAt = this.searchTerm.endedAt.toString();
      }

      if (this.searchHostId) {
        filter.hostId = this.searchHostId;
      }

      return filter;
    },
    async downloadFile(): Promise<void> {
      if (!this.searchTerm.startedAt) {
        return this.$modal.show({
          title: '엑셀 다운로드 실패',
          message: '엑셀 다운로드를 하려면 시작일을 설정해 주셔야 합니다.',
          type: 'warning',
        });
      }
      if (this.searchHostId) {
        return this.$modal.show({
          title: '엑셀 다운로드 실패',
          message: '엑셀 다운로드를 하려면 호스트 선택을 해제해야 합니다.',
          type: 'warning',
        });
      }
      const yearMonth = this.$moment(this.searchTerm.startedAt).format(
        'YYYY-MM'
      );
      try {
        const result = await service.generateTaxInvoiceDepositFile(yearMonth);

        if (!result.success) {
          return this.$modal.show({
            title: '엑셀 다운로드 실패',
            message: result.message,
            type: 'warning',
          });
        }

        window.location.href = result.url;
      } catch (err) {
        this.showErrorAlert(err, '엑셀 다운로드 실패');
      }
    },
  },
});
