







import Vue from 'vue';
import AccountList from '../components/AccountList.vue';
import AccountSearchForm from '../components/AccountSearchForm.vue';

export default Vue.extend({
  name: 'AccountListContainer',
  components: {
    AccountList,
    AccountSearchForm,
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
