var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{attrs:{"no-body":"","shadow-size":"0"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.totalCount > 0)?_c('b-pagination-nav',{staticClass:"mt-3",attrs:{"align":"center","link-gen":_vm.linkGen,"number-of-pages":_vm.numberOfPages,"use-router":""}}):_vm._e()]},proxy:true}])},[_c('b-card-header',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"1.2rem"}},[_vm._v(" 검색 결과: "+_vm._s(_vm.totalCount))])]),(_vm.loading > 0)?_c('spinner',{staticStyle:{"min-height":"200px"}}):_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center center-head",attrs:{"empty-text":"검색 결과가 없습니다.","header-row-class-name":"thead-light","header-cell-class-name":"center-table","data":_vm.taxInvoices}},[_c('el-table-column',{attrs:{"label":"호스트","prop":"host.name"}}),_c('el-table-column',{attrs:{"label":"일자","prop":"date"}}),_c('el-table-column',{attrs:{"min-width":"200","label":"제목","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.title)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"법인유형","class-name":"center-table"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('tax-player-type-label',{attrs:{"type":row.businessInfo.taxPayerType}})]}}])}),_c('el-table-column',{attrs:{"label":"법인명","class-name":"center-table","prop":"businessInfo.taxPayerName"}}),_c('el-table-column',{attrs:{"label":"관리 코드","class-name":"center-table","prop":"businessInfo.companyCode"}}),_c('el-table-column',{attrs:{"label":"공급가","class-name":"center-table"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('money-label',{attrs:{"amount":_vm.toMoney(row.surTax.supplyPrice, row.surTax.currencyCode)}}),_vm._v(" 원 ")]}}])}),_c('el-table-column',{attrs:{"label":"부가세","class-name":"center-table"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('money-label',{attrs:{"amount":_vm.toMoney(row.surTax.tax, row.surTax.currencyCode)}}),_vm._v(" 원 ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }